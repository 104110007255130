import type { GetServerSideProps, NextPage } from "next";
import Head from "next/head";
import { Container } from "react-bootstrap";

import AppointmentSearch from "@/components/AppointmentSearch";
import Layout from "@/components/UI/Layout";

import {
  PageLoading,
  getSearchStateFromUrl,
  fetchAllData,
  fetchSSR,
  useInitSearchPage
} from "@/helpers";
import { DataProps, SearchState } from "@/types";

type SearchPageProps = {
  initSearch: Partial<SearchState>;
} & DataProps;

const SearchPage: NextPage<SearchPageProps> = (props) => {
  const { initSearch, ...dataProps } = props;
  const isLoading = useInitSearchPage(dataProps, initSearch);

  /* Loading Page */
  if (isLoading) return <PageLoading />;

  return (
    <Layout>
      <Head>
        <title>{process.env.NEXT_PUBLIC_NAME} | Search</title>
        <meta name="description" content="Search for an appointment." />
        <meta
          name="keywords"
          content="doctor, medical, appointment, scheduling"
        />
      </Head>
      <Container
        style={{
          marginBottom: "5rem" // important for mobile
        }}
      >
        <AppointmentSearch />
      </Container>
    </Layout>
  );
};
export default SearchPage;

export const getServerSideProps: GetServerSideProps<SearchPageProps> = async ({
  resolvedUrl
}) => {
  const searchState = getSearchStateFromUrl(resolvedUrl);

  const props = await fetchSSR(
    async () => ({
      ...(await fetchAllData(searchState, true)),
      initSearch: searchState
    }),
    {
      initSearch: {}
    }
  );

  return { props };
};
