import Head from "next/head";
import { ReactNode } from "react";
import { Container } from "react-bootstrap";

import Navbar from "@/components/UI/Navbar";
import ForceSwitchStage from "@/components/DEV/ForceSwitchStage";
import { isDev } from "@/helpers";

const MANIFEST = "/manifest.json";
const ICON = "/icon.png";

const Layout = (props: { children: ReactNode }): JSX.Element => {
  return (
    <>
      <Head>
        <title>{process.env.NEXT_PUBLIC_NAME}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="manifest" href={MANIFEST} />
        <link rel="icon" href={ICON} />
        <link rel="apple-touch-icon" href={ICON} />
        <meta name="theme-color" content="#ff3c44" />
      </Head>
      <Navbar title="Bodhi Appointment Scheduling" homePage="/" />
      {/* DEV */}
      <Container>{isDev() && <ForceSwitchStage />}</Container>
      {props.children}
    </>
  );
};
export default Layout;
