import { Col, Row } from "react-bootstrap";

import { useAppointment } from "@/store";
import { AppointmentStage } from "@/types";

import classes from "./ForceSwitchStage.module.scss";

const ForceSwitchStage = (): JSX.Element => {
  const stages = [
    "SEARCH",
    // "PRACTICE",
    "INFO",
    "PARENT",
    "CONTACT",
    "INSURANCE",
    "PRE_SCREENING",
    "COSTS1",
    "QUESTIONS",
    "COSTS2",
    "SIGN",
    "PAYMENT",
    "DONE"
  ]; // Ordered!
  const app = useAppointment();

  return (
    <Row className={classes.nav}>
      {stages.map((s, i) => (
        <Col
          key={i}
          className={
            classes.item + " " + (app.stage === i ? classes.active : "")
          }
          onClick={() => app.forceSwitchStage(i as AppointmentStage)}
        >
          <p>{s}</p>
        </Col>
      ))}
    </Row>
  );
};
export default ForceSwitchStage;
