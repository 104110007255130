import { Col, Container, Row } from "react-bootstrap";

import classes from "./Navbar.module.scss";

const Navbar = (props: { title?: string; homePage?: string }): JSX.Element => {
  return (
    <Container>
      <Row className={classes.navbar}>
        <Col>
          <h1>{props.title ?? "Appointments"}</h1>
          <i
            className={`fas fa-home ${classes.icon}`}
            onClick={() => (window.location.href = props.homePage ?? "/")}
          />
        </Col>
      </Row>
    </Container>
  );
};
export default Navbar;
